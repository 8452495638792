var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.accept
    ? _c("div", { staticClass: "video-container" }, [
        _vm.check4Cookie()
          ? _c("div", { staticClass: "text-end" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default btn-sm",
                  on: {
                    click: function($event) {
                      return _vm.deleteCookie()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.voMsg("tpl.video.revokeConsent")) +
                      "\n        "
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("iframe", {
          staticClass: "video",
          attrs: {
            width: "100%",
            height: "700",
            src: _vm.getUrl(),
            frameborder: "0",
            allowfullscreen: "",
            allow: "vr"
          }
        })
      ])
    : _c("div", { staticClass: "video-hint" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.voMsg("tpl.iframe.warning")) +
              "\n        "
          ),
          _vm.type === "YouTube"
            ? _c("div", {
                domProps: { innerHTML: _vm._s(_vm.voMsg("tpl.video.youtube")) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "Vimeo"
            ? _c("div", {
                domProps: { innerHTML: _vm._s(_vm.voMsg("tpl.video.vimeo")) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "GoogleMaps"
            ? _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.voMsg("tpl.video.GoogleMaps"))
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "Instagram"
            ? _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.voMsg("tpl.video.Instagram"))
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "Matterport"
            ? _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.voMsg("tpl.video.Matterport"))
                }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "button-line" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-sm",
              on: { click: _vm.showContent }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.voMsg("tpl.video.showOnce")) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-sm",
              on: { click: _vm.allowContent }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.voMsg("tpl.video.alwaysShow")) +
                  "\n        "
              )
            ]
          )
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }