<template>
    <span>
      {{getFormattedValue}}
    </span>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'format-currency',
    	props: {
    		amount: Number
    	},
    	data: function() {
    		return {

    		};
    	},
    	mounted: function() {


    	},

    	computed: {
    		getFormattedValue: function() {
    			return Number((this.amount) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " €";
    		}
    	}
    };
</script>