import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';

import {
	fireFormSubmit
}
from 'voUtils/TagEx.js';

import {
	doPost
}
from 'voUtils/RestEx.js';

import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findPreviousSiblingByName
}
from 'voUtils/BrowserEx.js';

export default {
	voVueComponent: 'voffice-form',
	data: function () {
		var fd = VOFFICE.testmode ? {
			forename: 'John',
			surname: 'Doe',
			email: 'test@test.de',
			mobile: '342344 234234',
			message: 'Hallo World!'
		} : {};
		return {
			formData: fd,
			status: 'ready',
			travelperiod: '',
			guests: {
				adults: '',
				children: '',
				babys: '',
				petsCount: ''
			},
			error: undefined

		};
	},
	props: {
		subject: String,
		ngaction: String,
		bookingData: Object
	},
	computed: {
		periodLabel: function () {
			let label = '';
			if (this.formData.from) {
				label = this.voFormatDate(this.formData.from, 'withDay');
			}
			if (this.formData.till) {
				label += ' - ' + this.voFormatDate(this.formData.till, 'withDay');
			}

			return label;
		},
		customGuestLabel: function () {
			let label;
			var sd = this.guests;
			if (sd.adults) {
				label = sd.adults + ' ' + this.voMsgPl('adult.pl', sd.adults);
				this.formData.adults = sd.adults;
				if (sd.children > 0) {
					label += ', ' + sd.children + ' ' + this.voMsgPl('child.pl', sd.children);
					this.formData.children = sd.children;
				}
				if (sd.babys > 0) {
					label += ', ' + sd.babys + ' ' + this.voMsgPl('baby.pl', sd.babys);
					this.formData.babys = sd.babys;
				}
				if (sd.petsCount > 0) {
					label += ', ' + sd.petsCount + ' ' + this.voMsgPl('pet.pl', sd.petsCount);
					this.formData.petsCount = sd.petsCount;
				}
			}
			return label;
		},
		setUnit: function () {
			let el = document.querySelector("[data-unit-code]");
			if (el) {
				this.formData.object = el.getAttribute('data-unit-code');
			}
		},

	},
	created: function () {
		this.calListeners = [];
	},
	mounted: function () {

		var labels = {};
		var order = [];

		this.$el.querySelectorAll('[data-formdata-field]').forEach((el) => {
			var field = el.getAttribute('data-formdata-field');
			let label = el.getAttribute('voffice-form-field-label');

			if (!label) {
				let labelEl = findPreviousSiblingByName(el, 'label');
				if (!labelEl) {
					let id = el.getAttribute('id');
					if (id) {
						labelEl = this.$el.querySelector('[for="' + id + '"]');
					}
				}
				if (labelEl) {
					label = labelEl.textContent;
				}

			}

			if (label) {
				labels[field] = label;
			} else {
				V.warn(`could not find label for field ${field}, please use label, 'for' or 'voffice-form-field-label' attribute`);
			}

			order.push(field);

		});

		this.labels = labels;
		this.order = order;
	},
	methods: {
		getUnitId: function () {
			if (!this.unitId) {
				let el = document.querySelector("[data-unit-id]");
				if (el) {
					this.unitId = parseInt(el.getAttribute('data-unit-id'), 10);
				}
			}
			return this.unitId;
		},
		getCal: function (done) {
			if (this.cal) {
				console.log('kalendar schon da');
				done(this.cal);
			} else {
				this.calListeners.push(done);

				if (this.calListeners.length === 1) {

					var req = {
						unit: this.getUnitId()
					};

					doReq('getCal', req)
						.then((res) => {
							this.cal = res.cal;

							for (let l of this.calListeners) {
								l(res.cal);
							}
							this.calListeners.length = 0;
						});

				}
			}

		},


		checkDate: function (dateToCheck) {

			// Regulärer Ausdruck, um das gewünschte Format zu überprüfen
			var dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

			// Verwenden Sie die test() Methode des regulären Ausdrucks, um das Datum zu überprüfen
			if (dateFormatRegex.test(dateToCheck)) {
				return true;
			} else {
				return false;
			}
		},

		checkForm: function (id) {
			var formValid = true;
			var firstErrorField = null;
			var el = document.getElementById(id);
			var inputs = el.querySelectorAll("[required]");


			inputs.forEach(function (input) {
				//console.log('input.type::', input.type);
				if (input.type === 'select-one' && input.value === '') {
					input.classList.add('formerror');
					formValid = false;
					if (!firstErrorField) {
						firstErrorField = input;
					}
				} else if (input.type === 'text' && !input.value.trim()) {
					input.classList.add('formerror');
					formValid = false;
					if (!firstErrorField) {
						firstErrorField = input;
					}
				} else if (input.type === 'email' && !input.value.trim()) {
					input.classList.add('formerror');
					formValid = false;
					if (!firstErrorField) {
						firstErrorField = input;
					}
				} else if (input.type === 'date' && (!input.value.trim() || input.value == 'mm/dd/yyyy')) {
					input.classList.add('formerror');
					formValid = false;
					if (!firstErrorField) {
						firstErrorField = input;
					}
				} else if (input.type === 'textarea' && !input.value.trim()) {
					input.classList.add('formerror');
					formValid = false;
					if (!firstErrorField) {
						firstErrorField = input;
					}
				} else if (input.type == 'checkbox') {
					if (!$('#' + input.id).is(":checked")) {
						var innerElement = document.getElementById(input.id);
						var parentElement = innerElement.parentNode;
						parentElement.classList.add('formerror');
						formValid = false;
						if (!firstErrorField) {
							firstErrorField = input;
						}
					}

				} else {
					input.classList.remove('formerror');
				}
			});

			if (!formValid) {
				if (firstErrorField) {
					firstErrorField.focus();
					var offsetTop = firstErrorField.offsetTop;
					window.scrollTo({
						top: offsetTop - 50,
						behavior: 'smooth'
					});
				}
				//console.log("before return");
				return;
			} else {
				//console.log("before submit");
				this.submit();
			}
		},

		transformDate: function (originalDate) {

			// Create a Date object from the original date
			var dateObj = new Date(originalDate);

			// Extract day, month, and year from the Date object
			var day = dateObj.getDate();
			var month = dateObj.getMonth() + 1; // Months in JavaScript are zero-based
			var year = dateObj.getFullYear();

			// Add leading zeros if day or month is single-digit
			if (day < 10) {
				day = "0" + day;
			}

			if (month < 10) {
				month = "0" + month;
			}

			// Create the desired format "DD.MM.YYYY"
			var convertedDate = day + "." + month + "." + year;
			return convertedDate;
		},

		checkEmail: function (e) {

			var email = e.target.value;

			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
				$('#email-error').remove();
				$(":submit").prop('disabled', false);
				return true;
			} else {
				$(":submit").prop('disabled', true);
				$("#input_email").after('<div id="email-error" style="color:white; background: red; font-size: 13px; padding:2px 15px; margin-top:5px; border-radius: 5px">' + this.voMsg("email.invalid") + '</div>');
				return false;
			}
		},

		areChildrenWelcome: function () {
			if (this.childrenWelcome == undefined) {
				let el = document.querySelector("[data-children-welcome]");
				if (el) {
					this.childrenWelcome = el.getAttribute('data-children-welcome') == '1';
				} else {
					this.childrenWelcome = false;
				}
			}
			return this.childrenWelcome;
		},
		arePetsWelcome: function () {
			if (this.petsWelcome == undefined) {
				let el = document.querySelector("[data-pets-welcome]");
				if (el) {
					this.petsWelcome = el.getAttribute('data-pets-welcome') == '1';
				} else {
					this.petsWelcome = false;
				}
			}
			return this.petsWelcome;
		},

		scrollToResult: function () {
			/*let el = document.getElementById('form-inquiry');
			if (el) {
				el.scrollIntoView({
					behavior: 'smooth'
				});
			}*/
			let element = document.getElementById('form-inquiry');
			if (element) {
				$('html, body').animate({
					scrollTop: $("#form-inquiry").offset().top - 150
				}, 300);
			}
		},

		submit: function () {
			console.log('submitting from...');
			this.status = 'submitting';


			var data = {
				subject: this.subject,
				form: DateEx.formatDates(this.formData, undefined, VOFFICE.mlang),
				search: DateEx.encodeDates(this.$parent.searchData),
				unit: this.$parent.getUnitId && this.$parent.getUnitId(),
				labels: this.labels,
				order: this.order
			};

			const formDataCopy = Object.assign({}, this.formData);

			if (formDataCopy.from && this.checkDate(formDataCopy.from)) {
				var from = this.transformDate(formDataCopy.from);
				formDataCopy.from = from;
			}
			if (formDataCopy.till && this.checkDate(formDataCopy.till)) {
				var till = this.transformDate(formDataCopy.till);
				formDataCopy.till = till;
			}

			fireFormSubmit(data);
			this.error = undefined;

			doPost(this.ngaction || 'saveMessage', {
					subject: this.subject,
					form: DateEx.formatDates(formDataCopy, undefined, VOFFICE.mlang),
					search: DateEx.encodeDates(this.$parent.searchData),
					unit: this.$parent.getUnitId && this.$parent.getUnitId(),
					labels: this.labels,
					order: this.order
				})
				.then((res) => {
					this.status = 'done';
					this.scrollToResult();
				})
				.catch((e) => {
					this.status = 'failed';
					this.error = e;
				});


		}
	}
};