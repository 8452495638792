var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showing
    ? _c("div", { staticClass: "cookie-dlg" }, [
        _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "header" }, [
            _c(
              "h4",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.setup,
                    expression: "!setup"
                  }
                ]
              },
              [_vm._v(_vm._s(_vm.voMsg("cookie.header")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.setup,
                    expression: "setup"
                  }
                ]
              },
              [
                _c(
                  "h4",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.setup,
                        expression: "setup"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(_vm.voMsg("cookie.setup")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "close-setup",
                    on: { click: _vm.onCloseBtnClick }
                  },
                  [_c("i", { staticClass: "far fa-times" })]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.setup,
                  expression: "setup"
                }
              ],
              staticClass: "body"
            },
            [
              _c("div", { staticClass: "checkboxs" }, [
                _c("input", {
                  attrs: {
                    type: "checkbox",
                    id: "cookie_own",
                    onclick: "return false;",
                    checked: ""
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "cookie_own" } }, [
                  _vm._v(_vm._s(_vm.voMsg("cookies.own")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "checkboxs" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.showOpenstreetMaps,
                      expression: "showOpenstreetMaps"
                    }
                  ],
                  attrs: { type: "checkbox", id: "openstreetmap" },
                  domProps: {
                    checked: Array.isArray(_vm.showOpenstreetMaps)
                      ? _vm._i(_vm.showOpenstreetMaps, null) > -1
                      : _vm.showOpenstreetMaps
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.showOpenstreetMaps,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.showOpenstreetMaps = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.showOpenstreetMaps = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.showOpenstreetMaps = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "openstreetmaps" } }, [
                  _vm._v(_vm._s(_vm.voMsg("cookies.googlemaps")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "checkboxs" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cookiesOk,
                      expression: "cookiesOk"
                    }
                  ],
                  attrs: { type: "checkbox", id: "cookie_others" },
                  domProps: {
                    checked: Array.isArray(_vm.cookiesOk)
                      ? _vm._i(_vm.cookiesOk, null) > -1
                      : _vm.cookiesOk
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.cookiesOk,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.cookiesOk = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.cookiesOk = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.cookiesOk = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "cookie_others" } }, [
                  _vm._v(_vm._s(_vm.voMsg("cookies.others")))
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.setup,
                  expression: "!setup"
                }
              ],
              staticClass: "body"
            },
            [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.introMsg) } }),
              _vm._v(" "),
              _vm.VOFFICE.settings.privacyExternalLink
                ? _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "footer-link",
                        attrs: {
                          href: _vm.VOFFICE.settings.privacyExternalLink,
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(_vm.voMsg("cookies.details")))]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.VOFFICE.settings.privacyExternalLink
                ? _c("voffice-doc-box", { attrs: { "doc-id": "privacy" } }, [
                    _c(
                      "a",
                      { staticClass: "privacy-link", attrs: { href: "#" } },
                      [_vm._v(_vm._s(_vm.voMsg("cookies.details")))]
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.setup,
                  expression: "setup"
                }
              ],
              staticClass: "footer"
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.onSaveBtnClick }
                },
                [_vm._v(_vm._s(_vm.voMsg("cookies.saveselection")))]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.setup,
                  expression: "!setup"
                }
              ],
              staticClass: "footer"
            },
            [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-sm",
                    attrs: { type: "button" },
                    on: { click: _vm.onSaveBtnClickEssentiell }
                  },
                  [_vm._v(_vm._s(_vm.voMsg("cookies.onlynecessary")))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-sm",
                    attrs: { type: "button" },
                    on: { click: _vm.onSetupBtnClick }
                  },
                  [_vm._v(_vm._s(_vm.voMsg("cookies.setup")))]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-end mt-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.onOkBtnClick }
                  },
                  [_vm._v(_vm._s(_vm.voMsg("cookies.ok")))]
                )
              ])
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }