import V from 'voUtils/V.js';

import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {

	data: function () {
		return {
			openstreetmap: false,
			units: [],
			count: null,

		};
	},

	beforeMount: function () {
		const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
		var cookieName = 'allowOpenstreetmap_' + suffix;
		var allowOpenstreet = localStorage.getItem(cookieName);
		if (allowOpenstreet) {
			this.openstreetmap = true;
		}

		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var region = null;
		let el = document.querySelector("[data-region-path]");
		if (el) {
			region = el.getAttribute('data-region-path');
			console.log('region::', region);
		} else {
			console.log('element region is not found..................');
		}
		var req;
		if (region) {
			req = {
				fields: this.resultFields,
				filter: {
					'region': region
				},
				max: 6,
				sorting: 'random'
			};

			doReq('searchUnits', req)
				.then((res) => {
					var foundUnits = [];
					if (res.units.length) {
						for (let i = 0; i < res.units.length; i++) {
							foundUnits.push(res.units[i]);
						}
					}
					this.count = foundUnits.length;
					this.units = foundUnits;

				});
		}

	},

	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},

	methods: {

		setCookie: function (name) {
			localStorage.setItem(name, true);
		},

		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName);
			this.openstreetmap = true;
		},

		showOpenstreetmap: function () {
			this.openstreetmap = true;
		},
		makeBreadcrumb: function (path, name) {
			var str = '';
			if (path) {
				var pathParts = path.split('/');
				if (pathParts.length > 0) {
					for (let i = 0; i < pathParts.length - 1; i++) {
						if (i < 1) {
							str += '<a href="./' + pathParts[0] + '">' + pathParts[0] + '</a> / ';
						} else if (i === 1) {
							str += '<a href="./' + pathParts[0] + '/' + pathParts[1] + '">' + pathParts[1] + '</a> / ';
						} else if (i === 2) {
							str += '<a href="./' + pathParts[0] + '/' + pathParts[1] + '/' + pathParts[2] + '">' + pathParts[2] + '</a> / ';
						} else if (i === 3) {
							str += '<a href="./' + pathParts[0] + '/' + pathParts[1] + '/' + pathParts[2] + '/' + pathParts[3] + '">' + pathParts[3] + '</a> / ';
						} else if (i === 4) {
							str += '<a href="./' + pathParts[0] + '/' + pathParts[1] + '/' + pathParts[2] + '/' + pathParts[3] + '/' + pathParts[4] + '">' + pathParts[4] + '</a> / ';
						}
					}
				}
				//console.log('pathParts::', pathParts);
				str += name;
			}

			return str;
		},
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},

		applySlider: function () {
			$(".owl-carousel.region").owlCarousel({
				loop: false,
				margin: VOFFICE.settings.olwCarouselMargin,
				smartSpeed: 1000,
				autoplay: false,
				nav: VOFFICE.settings.olwCarouselNav,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					993: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		},
	}

};